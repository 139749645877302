import { button1, button2, button3, nft1, nft2, nft3, nft4, nft5, nft6 , nft7, nft8, nft9, nft10, nft11, nft12, nft13, nft14, nft15, nft16, nft17, nft18, nft19, nft20, nft21 } from '../assets'
import React, { useState, useEffect } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { Gallery } from './index.js'
import { LuDog } from "react-icons/lu";
import { FaQuestion } from "react-icons/fa6";
import { FaMoon } from "react-icons/fa";



const Page2 = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const simages   
   = [nft1, nft2, nft3, nft4, nft5, nft6, nft7, nft8, nft9, nft10, nft11, nft12, nft13, nft14, nft15, nft16, nft17, nft18, nft19, nft20, nft21];
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % simages.length);
      }, 2000);

      return () => clearInterval(intervalId);
    }, [simages.length]);

    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleMode = () => {
      setIsDarkMode(!isDarkMode);
    };
  

  return (
    <><div className={`overflow-x-hidden ${
      isDarkMode ? "bg-amber-400" : "bg-zinc-950"
    }`}><div className="flex flex-col md:flex-row items-center justify-center w-full  mx-auto pt-4 md:p-8 md:gap-8 md:px-20 lg:px-32 lg:gap-16">
          <div className='md:4 lg:pl-1 flex items-center left-0 gap-4 md:gap-6 lg:gap-8 top-0'>
              <img src={simages[currentImageIndex]} className='w-16 md:w-24' alt='Doginal Buds NFT' />
              <div className='items-center flex opacity-100 rounded-lg bg-black'>
              <span className='lg:whitespace-nowrap font-medium text-center flex flex-col items-center pixelfont px-3 py-2 text-white z-10'><span className='whitespace-nowrap font-medium text-3xl'>DOGINAL BUDS </span><span className='whitespace-nowrap pixelfont'>Your Little Friend 💛</span></span>

              </div>
          </div>
          <div className='flex md:flex-col lg:flex-row flex-col items-center gap-2 md:gap-4 lg:gap-8 pt-4 md:pt-0 lg:pl-12'>
              <a href="https://x.com/DoginalBuds"><button type="button" class="pixelfont px-4 py-3 text-xl font-bold text-center text-white bg-black rounded-lg hover:bg-stone-950 hover:duration-200 schoolbell-regular"><FaXTwitter /> </button></a>
              <a href="https://doggy.market/nfts/doginal-buds"><button type="button" class="pixelfont px-2 py-2 flex items-center text-3xl font-black text-center text-white hover:bg-green-600 hover:duration-200 schoolbell-regular" style={{ backgroundImage: `url(${button1})` }}> Doggy.Market</button></a>
              <a href="https://doge.ordinalswallet.com/collection/doginalbuds"><button type="button" class="whitespace-nowrap pixelfont px-2 py-2 flex items-center text-3xl font-black text-center text-white hover:bg-green-600 hover:duration-200 schoolbell-regular" style={{ backgroundImage: `url(${button2})` }}> Ordinals Wallet</button></a>
              <a href=""><button type="button" class="whitespace-nowrap pixelfont px-2 py-2 flex items-center text-3xl font-bold text-center text-white hover:bg-green-600 hover:duration-200 schoolbell-regular" style={{ backgroundImage: `url(${button3})` }}>Doge Labs</button></a>
          </div>
          <div className='md:hidden lg:flex md:pl-0 lg:pl-48 flex pt-2 md:pt-0 items-center gap-8'>
          <a><button type="button" class="px-2 py-2 flex items-center text-xl font-bold text-center text-white bg-black  rounded-lg hover:bg-amber-400 hover:duration-200 schoolbell-regular"> <FaQuestion />

          </button></a>
          <a ><button type="button" onClick={toggleMode} class="px-2 py-2 flex items-center text-xl font-bold text-center text-white bg-black rounded-lg hover:bg-amber-400 hover:duration-200 schoolbell-regular">         {isDarkMode ? (
            <FaMoon />
        ) : (
          <LuDog />
        )}</button></a>

          </div>
      </div>
      <div className="flex items-center">
  <div className="w-full pb-4">
    <div className="">
      <div className="">
        <div className="">
          <Gallery />
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
     </>
  );
};

export default Page2;