import { Page2 } from './components'

function App() {
  return (
    <>
    <Page2/>

    </>
  );
}

export default App;
